/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
#home {
  width: 125px;
  margin: 17px 0 19px 2px;
}
.belzona {
  margin: 26px 0 0 0;
}
#head {
  margin: 33px 0 23px;
}
.desk {
  max-width: 916px;
}
.navigation {
  float: right;
  width: auto;
  margin: 12px 0 0;
}
.mood {
  padding: 0;
  height: 397px;
}
.slide .cb-landscape {
  width: 100% !important;
  height: auto !important;
}
h1 {
  font-size: 70px;
  font-size: 7rem;
}
.footerplacement {
  min-height: 100%;
  position: relative;
}
.cb-layout1 .areawrapper--base {
  padding-bottom: 2em;
}
#services {
  float: left;
}
#services > .meta {
  padding: 1.3em 1.2em;
}
.bwm {
  float: right;
  margin-top: 18px;
}
.service_linkedin {
  float: right;
  margin: -7px 0 0 14px;
}
.home,
div.sub2,
.sub2 > .item {
  width: auto;
}
.navigation a {
  border: 0;
  padding: 1.75em 1.4em;
}
div.sub3 {
  margin-top: 52px;
  width: 23.36244541%;
}
div.sub3 .menu {
  padding-top: 0.97em;
  padding-bottom: 0.97em;
}
.sub3 > .init > .menu {
  border-top: 0;
}
.cb-layout1 .area {
  width: 100%;
}
.cb-layout1 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area h2,
.cb-layout1 .area .foot {
  margin-right: 1.06837607%;
  margin-left: 1.06837607%;
}
.cb-layout1 .area .part,
.cb-layout1 .area > .grid table {
  margin-right: 1.06837607%;
  margin-left: 1.06837607%;
  width: 97.86324786%;
}
.cb-layout1 .area .tiny {
  width: 47.86324786%;
}
.cb-layout1 .area > .slim {
  width: 50%;
}
.cb-layout1 .area > .slim h2,
.cb-layout1 .area > .slim .foot,
.cb-layout1 .area > .slim .part,
.cb-layout1 .area > .slim.grid table {
  margin-right: 2.13675214%;
  margin-left: 2.13675214%;
}
.cb-layout1 .area > .slim .part,
.cb-layout1 .area > .slim.grid table {
  width: 95.72649573%;
}
.cb-layout1 .area > .slim .tiny {
  width: 95.72649573%;
}
.cb-layout2 .area {
  width: 100%;
}
.cb-layout2 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area h2,
.cb-layout2 .area .foot {
  margin-right: 1.42450142%;
  margin-left: 1.42450142%;
}
.cb-layout2 .area .part,
.cb-layout2 .area > .grid table {
  margin-right: 1.42450142%;
  margin-left: 1.42450142%;
  width: 97.15099715%;
}
.cb-layout2 .area .tiny {
  width: 30.48433048%;
}
.cb-layout2 .area > .slim .part,
.cb-layout2 .area > .slim.grid table {
  width: 97.15099715%;
}
.cb-layout2 .area > .slim .tiny {
  width: 30.48433048%;
}
.cb-layout3 .area {
  width: 100%;
}
.cb-layout3 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area h2,
.cb-layout3 .area .foot {
  margin-right: 1.09170306%;
  margin-left: 1.09170306%;
}
.cb-layout3 .area .part,
.cb-layout3 .area > .grid table {
  margin-right: 1.09170306%;
  margin-left: 1.09170306%;
  width: 97.81659389%;
}
.cb-layout3 .area .tiny {
  width: 31.14992722%;
}
.cb-layout3 .area > .slim .part,
.cb-layout3 .area > .slim.grid table {
  width: 97.81659389%;
}
.cb-layout3 .area > .slim .tiny {
  width: 31.14992722%;
}
.cb-layout4 .area,
.cb-layout5 .area {
  width: 100%;
}
.cb-layout4 .area > .unit,
.cb-layout5 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout4 .area h2,
.cb-layout5 .area h2,
.cb-layout4 .area .foot,
.cb-layout5 .area .foot {
  margin-right: 1.42450142%;
  margin-left: 1.42450142%;
}
.cb-layout4 .area .part,
.cb-layout5 .area .part,
.cb-layout4 .area > .grid table,
.cb-layout5 .area > .grid table {
  margin-right: 1.42450142%;
  margin-left: 1.42450142%;
  width: 97.15099715%;
}
.cb-layout4 .area .tiny,
.cb-layout5 .area .tiny {
  width: 47.15099715%;
}
.cb-layout4 .area > .slim,
.cb-layout5 .area > .slim {
  width: 50%;
}
.cb-layout4 .area > .slim h2,
.cb-layout5 .area > .slim h2,
.cb-layout4 .area > .slim .foot,
.cb-layout5 .area > .slim .foot,
.cb-layout4 .area > .slim .part,
.cb-layout5 .area > .slim .part,
.cb-layout4 .area > .slim.grid table,
.cb-layout5 .area > .slim.grid table {
  margin-right: 2.84900285%;
  margin-left: 2.84900285%;
}
.cb-layout4 .area > .slim .part,
.cb-layout5 .area > .slim .part,
.cb-layout4 .area > .slim.grid table,
.cb-layout5 .area > .slim.grid table {
  width: 94.3019943%;
}
.cb-layout4 .area > .slim .tiny,
.cb-layout5 .area > .slim .tiny {
  width: 94.3019943%;
}
.cb-layout6 .main {
  width: 100%;
}
.cb-layout6 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout6 .main h2,
.cb-layout6 .main .foot {
  margin-right: 1.42450142%;
  margin-left: 1.42450142%;
}
.cb-layout6 .main .part,
.cb-layout6 .main > .grid table {
  margin-right: 1.42450142%;
  margin-left: 1.42450142%;
  width: 97.15099715%;
}
.cb-layout6 .main .tiny {
  width: 47.15099715%;
}
.cb-layout6 .main > .slim {
  width: 50%;
}
.cb-layout6 .main > .slim h2,
.cb-layout6 .main > .slim .foot,
.cb-layout6 .main > .slim .part,
.cb-layout6 .main > .slim.grid table {
  margin-right: 2.84900285%;
  margin-left: 2.84900285%;
}
.cb-layout6 .main > .slim .part,
.cb-layout6 .main > .slim.grid table {
  width: 94.3019943%;
}
.cb-layout6 .main > .slim .tiny {
  width: 94.3019943%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south h2,
.south .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.south .part,
.south > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 100%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.cb-layout2 .tall.tall,
.cb-layout3 .tall.tall {
  width: 64.1025641%;
}
.cb-layout2 .show.tiny:first-child,
.cb-layout3 .show.tiny:first-child {
  margin-left: 0;
}
.part.payp.tile,
.part.payp.tile .papc {
  position: static;
}
.part.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.part.payp.tile input.numb {
  position: static !important;
  float: left;
}
.part.payp.tile .name {
  float: left;
  width: 60%;
}
.slim .tiny.payp.tile,
.slim .tiny.payp.tile .papc {
  position: relative;
}
.slim .tiny.payp.tile .papc {
  margin-bottom: -2em;
  padding-bottom: 2em;
}
.slim .tiny.payp.tile input.numb {
  position: absolute !important;
  bottom: 0.4em;
}
.slim .tiny.payp.tile input.numb.grow {
  position: absolute !important;
}
.slim .tiny.payp.tile .name {
  float: none;
  width: 100%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.cb-layout1 .area {
  padding: 40px 0;
  margin-left: -1.09170306%;
  width: 102.18340611%;
}
.cb-layout2 .area,
.cb-layout3 .area {
  margin: 50px 0 30px;
}
.cb-layout2 .area {
  margin: 42px 0 30px;
}
.cb-layout2 .area,
.cb-layout4 .area,
.cb-layout5 .area {
  width: 76.63755459%;
}
.cb-layout2 .area,
.cb-layout4 .area,
#edit.cb-layout3 .area,
#edit.cb-layout5 .area {
  float: right;
  margin-right: -1.09170306%;
}
.cb-layout4 .area,
.cb-layout5 .area {
  margin-top: 30px;
}
.cb-layout5 .area {
  margin-left: -1.09170306%;
}
.cb-layout6 .main {
  margin-left: -1.09170306%;
  width: 76.63755459%;
}
.south {
  width: 23.36244541%;
  float: right;
  margin-top: 10px;
}
.cb-layout1 .unit {
  text-align: left;
}
#view.cb-layout1 .goto {
  width: auto;
}
.cb-layout1 .side > .unit {
  float: right;
}
.cb-layout2 .unit .body,
.cb-layout2 .show.tiny:first-child,
.cb-layout3 .unit .body,
.cb-layout3 .show.tiny:first-child {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout4 .wide .show.tiny + .lead.tiny,
.cb-layout5 .wide .show.tiny + .lead.tiny {
  margin-top: 0;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.shop {
  left: 50%;
  margin-left: -270px;
  width: 540px;
}
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/******* module-shop-large.less 2013-1-16 *******/
.cb-shop-tabs > li {
  display: inline-block;
  margin-bottom: -1px;
}
.cb-shop-tab {
  margin-right: 0.3em;
  border-bottom-width: 1px;
  border-radius: 4px 4px 0 0;
}
.cb-shop-tab.selected {
  border-bottom-color: #f5f5f5;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  width: 32%;
}
#disp.shop textarea {
  width: 67%;
}
#disp.shop .file,
#disp.shop .text,
#disp.shop select {
  width: 67%;
}
#disp.shop input.zip {
  clear: none;
  width: 10%;
}
#disp.shop input.city,
#disp.shop .cb-shop-currency > select,
#disp.shop .cb-shop-countries > select,
#disp.shop .cb-shop-regions > select {
  width: 55%;
}
#disp.shop input.city {
  width: 56%;
}
.cb-shop-currency > select + a {
  width: 6%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: none;
  margin-bottom: 0.5em;
}
.cb-shipping-range-label-input,
.cb-shipping-range-value-input {
  width: 100% !important;
}
#disp .cb-shop-country > select {
  width: 67%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart .ship,
.cb-shoppingcart fieldset.tiny {
  float: left;
  width: 49%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart fieldset.tiny {
  margin-right: 2%;
}
/*# sourceMappingURL=./screen-large.css.map */